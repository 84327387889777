import { getConfig } from '../../config'
import { ColorPalette, Widget } from '../../types'
import { useGetWidgetData } from '../../utils/useGetWidgetData'
import styles from '../../widget.module.styl'
import { ChartOptions, ChartResolver } from '../Charts'
import { WidgetMessage } from '../WidgetMessage/WidgetMessage'
import { WidgetModalProps } from '../WidgetModal/WidgetModal'
import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  SidePanel,
  Spinner,
  Text,
  TextField,
} from '@nike/eds'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form'

type WidgetSidePanelProps<T> = WidgetModalProps<T> & {
  accessToken: string
  chartOptions: ChartOptions
  editQueryProps: EditQueryProps
}

interface QueryParamsRow {
  parameter: string
  value: string
}

interface EditQueryProps {
  accessToken: string
  handleSaveWidget: (
    widget: Widget,
    queryParams: QueryParamsRow[],
    shouldBeSavedAsNew?: boolean
  ) => void
}

export const hasSaveWidgetHandler = (props?: Record<string, any>): props is EditQueryProps =>
  !!props?.handleSaveWidget

const defaultQueryParam = { parameter: '', value: '' }

const createSourceURLWithParams = (queryParams: QueryParamsRow[], url?: string) => {
  const reduceSourceURL = queryParams.reduce(
    (total: string, next: QueryParamsRow, nextIndex: number) =>
      total +
      `${nextIndex === 0 ? '?' : ''}${next.parameter}${
        next.parameter && next.value.trim() ? `=${next.value.trim()}` : ''
      }${nextIndex < queryParams.length - 1 && next.parameter ? '&' : ''}`,
    url || ''
  )

  // remove last ? or & from url
  const updatedSourceURL =
    reduceSourceURL.slice(-1) === '?' || reduceSourceURL.slice(-1) === '&'
      ? reduceSourceURL.slice(0, -1)
      : reduceSourceURL

  return updatedSourceURL
}

const queryParamsFieldArray = 'queryParams'

/**
 * Renders widget with custom query section
 */
export const WidgetSidePanel = <T extends Widget>({
  closeModal,
  widget,
  chartOptions,
  createSnack,
  editQueryProps: { handleSaveWidget, accessToken },
}: WidgetSidePanelProps<T>) => {
  const { env } = getConfig()
  const {
    dataSourceURL,
    dataQuery,
    type,
    id,
    name,
    refreshFrequency,
    timezoneSupport,
    customWidgetId,
    queryParams,
  } = widget
  const copyURL = async (url: string) => {
    await navigator.clipboard.writeText(url)
    createSnack?.({
      id: 'widget-data-url-copied',
      status: 'success',
      message: 'Data Source URL copied to clipboard',
    })
  }
  const { control, handleSubmit } = useForm<{ queryParams: QueryParamsRow[]; name: string }>({
    defaultValues: {
      queryParams: queryParams || [],
      name,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: queryParamsFieldArray,
  })
  const watchedParams = useWatch({ name: queryParamsFieldArray, control })
  const pureSourceURL = dataSourceURL?.split('?')[0]
  const updatedSourceURL = createSourceURLWithParams(watchedParams, pureSourceURL)
  const [isSaveWidgetPanelOpen, setIsSaveWidgetPanelOpen] = useState(false)
  const updatedName = name
  const {
    get,
    isFetching,
    errorMessage,
    data: widgetData,
  } = useGetWidgetData({
    accessToken,
    dataSourceURL, // pass init url
    type,
    dataQuery,
    env,
    id: id || name,
    refreshFrequency,
    customWidgetId,
    timezoneSupport: timezoneSupport || false,
  })

  const currentWidgetConfig = {
    ...widget,
    dataSourceURL: updatedSourceURL,
    name: updatedName,
  }

  useEffect(() => {
    if (fields?.length < 1) {
      append(defaultQueryParam)
    }
  }, [append, fields?.length])

  const save = () => {
    handleSaveWidget(currentWidgetConfig, watchedParams)
    closeModal()
  }
  const saveAsNew = () => {
    const shouldBeSavedAsNew = true
    handleSubmit((data) => {
      handleSaveWidget(
        { ...currentWidgetConfig, name: data.name },
        data.queryParams,
        shouldBeSavedAsNew
      )
    })()
    setIsSaveWidgetPanelOpen(false)
    closeModal()
  }

  const saveAsExisting = () => {
    handleSubmit((data) => {
      handleSaveWidget({ ...currentWidgetConfig, name: data.name }, data.queryParams)
    })()
    setIsSaveWidgetPanelOpen(false)
    closeModal()
  }

  return (
    <>
      <SidePanel
        hasScrim
        isOpen
        displayCheckOverride={process.env.NODE_ENV === 'test'}
        onDismiss={closeModal}
        className={styles.widgetDetailsSidePanel}
        headerSlot={widget.name}
        footerSlot={
          <ButtonGroup>
            <Button size='small' onClick={closeModal}>
              Close
            </Button>
            <Button size='small' variant='secondary' onClick={() => setIsSaveWidgetPanelOpen(true)}>
              Save as...
            </Button>
            <Button size='small' variant='secondary' onClick={save}>
              Save
            </Button>
          </ButtonGroup>
        }
        hideFade
      >
        <div className={styles.widgetDetailsSidePanelSection}>
          <div className={styles.widgetDetailsSidePanelChart}>
            {isFetching && (
              <div className={styles.spinner}>
                <Spinner role='progressbar' />
              </div>
            )}
            {errorMessage && <WidgetMessage text={errorMessage} />}
            {widgetData?.value?.data && (
              <ChartResolver
                type={widget.chartType || widgetData.value.type}
                data={widgetData.value?.data}
                columns={widgetData.value?.columns}
                responsiveHeight='100%'
                additionalClassName={styles.chart}
                size='large'
                isExpanded
                colorPalette={widget?.colorPalette || ColorPalette.CATEGORICAL}
                options={chartOptions}
              />
            )}
          </div>
          <div className={styles.dataSourceURLWrapper}>
            <Text font='title-6' className={styles.dataSourceURLTitle}>
              Data Source URL
            </Text>
            <div className={styles.dataSourceURLRow}>
              <TextField value={updatedSourceURL} id='url' label='url' hideLabel disabled />
              <Button
                onClick={async () => {
                  await get?.(updatedSourceURL)
                }}
                data-testid='run-query'
              >
                Run
              </Button>
              <Button
                size='small'
                variant='secondary'
                beforeSlot={<Icon name='Link' />}
                onClick={() => copyURL(updatedSourceURL)}
              >
                Copy Link
              </Button>
            </div>
          </div>
          <div className={styles.queryParamsWrapper}>
            <Text font='title-6' className={styles.queryParamsTitle}>
              Query Parameters
            </Text>
            <div className={styles.queryParamsContent}>
              {fields?.map((item, index) => (
                <div className={styles.queryParamsRow} key={item.id}>
                  <Controller
                    name={`queryParams.${index}.parameter`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id='parameter'
                        placeholder={'parameter'}
                        label={'parameter'}
                        hideLabel
                        data-testid={'query-parameter'}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`queryParams.${index}.value`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id='value'
                        placeholder={'value'}
                        label={'value'}
                        hideLabel
                        data-testid={'query-parameter-value'}
                        {...field}
                      />
                    )}
                  />
                  <div className={styles.removeItemButton}>
                    <IconButton
                      size='small'
                      icon='Close'
                      label='Delete'
                      onClick={() => {
                        remove(index)
                      }}
                      disabled={fields.length <= 1}
                      data-testid='remove-parameter'
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.addItemButtonWrapper}>
              <Button variant='secondary' onClick={() => append(defaultQueryParam)}>
                Add More
              </Button>
            </div>
          </div>
        </div>
      </SidePanel>
      {isSaveWidgetPanelOpen &&
        createPortal(
          <SidePanel
            isOpen
            hasScrim
            onDismiss={() => {
              setIsSaveWidgetPanelOpen(false)
            }}
            headerSlot={'Save as...'}
            footerSlot={
              <ButtonGroup>
                <Button size='small' onClick={() => setIsSaveWidgetPanelOpen(false)}>
                  Close
                </Button>
                <Button size='small' variant='secondary' onClick={saveAsNew}>
                  Save as New
                </Button>
                <Button size='small' variant='secondary' onClick={saveAsExisting}>
                  Save as Existing
                </Button>
              </ButtonGroup>
            }
          >
            <div>
              <Text font='title-6' className={styles.dataSourceURLTitle}>
                Widget name
              </Text>{' '}
              <Controller
                name={'name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    id='name'
                    placeholder={'name'}
                    label={'parameter'}
                    hideLabel
                    data-testid={'widget-name'}
                    {...field}
                  />
                )}
              />
            </div>
          </SidePanel>,
          document.getElementById('portal') || document.body
        )}
    </>
  )
}
