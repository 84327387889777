export type EnvType = 'prod' | 'prod-stage' | 'nonprod' | 'nonprod-stage' | 'dev'

type EnvUrlType = '-dev' | '-nonprod-stage' | '' | '-nonprod' | '-stage'

export interface AppConfig {
  env: EnvType
  platformConsoleApiUrl: string
}

const platformConsoleApiUrl = (env: EnvUrlType) =>
  process.env.REACT_APP_API || `https://api${env}-console.platforms.nike.com/graphql`

const dev: AppConfig = {
  env: 'dev',
  platformConsoleApiUrl: platformConsoleApiUrl('-dev'),
}

const nonprodStage: AppConfig = {
  env: 'nonprod-stage',
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod-stage'),
}

const nonprod: AppConfig = {
  env: 'nonprod',
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod'),
}

const prodStage: AppConfig = {
  env: 'prod-stage',
  platformConsoleApiUrl: platformConsoleApiUrl('-stage'),
}

const prod: AppConfig = {
  env: 'prod',
  platformConsoleApiUrl: platformConsoleApiUrl(''),
}

const locationHasEnvURL = (envUrl: string) =>
  window.location.origin.includes(`//${envUrl}.platforms.nike.com`)

export const getConfig = (env?: EnvType): AppConfig => {
  let config = dev

  if (env === 'prod' || locationHasEnvURL('console')) {
    config = prod
  } else if (env === 'prod-stage' || locationHasEnvURL('console-stage')) {
    config = prodStage
  } else if (env === 'nonprod' || locationHasEnvURL('console-nonprod')) {
    config = nonprod
  } else if (env === 'nonprod-stage' || locationHasEnvURL('console-nonprod-stage')) {
    config = nonprodStage
  } else if (env === 'dev' || locationHasEnvURL('console-dev')) {
    config = dev
  } else if (window.location.origin.includes('localhost')) {
    config = dev
  } else {
    config = prod
  }

  return config
}
