import { EnvType, getConfig } from '../config'
import { ChartData, isChartData, labels } from '../cx-widget'
import { QUERIES } from '../graphql/queries'
import { DataSourceType } from '../types'
import { getCXWidgetApiHandler } from '../widget-data-apis'
import { isGithubUrlValid, isValidURL } from './isValidURL'
import gql from 'graphql-tag'
import { useCallback, useEffect, useRef, useState } from 'react'

const headers = (accessToken: string, timezoneSupport: boolean) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
  ...(timezoneSupport && {
    'Timezone-Offset': new Date().getTimezoneOffset().toString(),
  }),
})

interface UseGetWidgetDataArgs {
  accessToken: string | null
  dataSourceURL: string
  type: DataSourceType
  dataQuery?: string | null
  refreshFrequency?: number
  id?: string
  env?: EnvType
  timezoneSupport?: boolean
  customWidgetId?: string | null
}

export interface UseGetWidgetDataReturn {
  errorMessage: string
  isFetching: boolean
  data?: ChartData
  get?: (customURL?: string) => Promise<void>
}

type ApiRequest = {
  method: 'GET'
  url: string
}

type GraphqlRequest = {
  method: 'POST'
  url: string
  query: string
  variables?: Record<string, string | number>
}

type Request = ApiRequest | GraphqlRequest

type GraphqlResult = { data: Record<string, { data: string }> }

const isGraphqlRequest = (requestSetting: Request): requestSetting is GraphqlRequest =>
  requestSetting.method === 'POST' && !!requestSetting.query

interface ChartCache {
  ttl: number
  data: ChartData
}

export const widgetCacheKey = (id: string) => `widget_cache_${id}`

const getDataFromCache = (id?: string) => {
  if (id) {
    const cacheItemRaw = window.localStorage.getItem(widgetCacheKey(id))
    if (cacheItemRaw) {
      const cacheItemParsed: ChartCache = JSON.parse(cacheItemRaw)

      if (!cacheItemParsed.ttl || Date.now() > cacheItemParsed.ttl) {
        // return cached data before refetch
        return { data: cacheItemParsed.data, shouldFetch: true }
      }

      return { data: cacheItemParsed.data, shouldFetch: false }
    }
  }

  return { data: undefined, shouldFetch: true }
}
const mapGraphqlResponseBody = (body: GraphqlResult, query?: string) => {
  const obj = gql`
    ${query}
  `
  const dataName = [...(obj as any).definitions][0]?.selectionSet.selections[0]?.name?.value || ''
  const data = body.data[dataName]?.data
  return data ? JSON.parse(data) : null
}

export const useGetWidgetData = ({
  accessToken,
  dataSourceURL,
  type,
  dataQuery,
  refreshFrequency,
  id: widgetId,
  customWidgetId,
  env,
  timezoneSupport = false,
}: UseGetWidgetDataArgs): UseGetWidgetDataReturn => {
  const id = customWidgetId ? `${widgetId}_${customWidgetId}` : widgetId
  const [widgetData, setWidgetData] = useState<
    { data: ChartData | undefined; shouldFetch: boolean } | undefined
  >(() => getDataFromCache(id))
  const [errorMessage, setErrorMessage] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const isCancelled = useRef(false)

  const fetchData = useCallback(
    async (requestSetting: Request) => {
      try {
        setErrorMessage('')
        setIsFetching(true)
        if (!accessToken) throw new Error('Access token denied')
        const response = await fetch(requestSetting.url, {
          cache: 'no-cache',
          headers: headers(accessToken, timezoneSupport),
          method: requestSetting.method,
          body: isGraphqlRequest(requestSetting)
            ? JSON.stringify({
                query: requestSetting.query,
                variables: {
                  ...requestSetting.variables,
                },
              })
            : undefined,
        })

        const responseBody = await response.json()
        if (!response.ok) {
          const errorResponse = isGraphqlRequest(requestSetting)
            ? responseBody?.errors[0]?.message
            : responseBody
          throw new Error(errorResponse || labels.errorFetchWidgetData(requestSetting.url))
        }

        const data = isGraphqlRequest(requestSetting)
          ? mapGraphqlResponseBody(responseBody, requestSetting.query)
          : responseBody

        if (!isCancelled.current) {
          if (isChartData(data)) {
            if (id && refreshFrequency) {
              const cache: ChartCache = {
                data,
                ttl: Date.now() + refreshFrequency * 1000,
              }
              window.localStorage.setItem(widgetCacheKey(id), JSON.stringify(cache))
            }
            setWidgetData({ data, shouldFetch: false })
          } else {
            throw new Error(labels.notValidWidgetData)
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(labels.errorFetchWidgetData(requestSetting.url))
        }
      }
      !isCancelled.current && setIsFetching(false)
    },
    [accessToken, id, refreshFrequency, timezoneSupport]
  )

  // API request
  const handleRequestToAPI = useCallback(
    async (customURL?: string) => {
      if (isValidURL(customURL || dataSourceURL)) {
        await fetchData({ method: 'GET', url: customURL || dataSourceURL })
      } else {
        setErrorMessage(labels.notValidURL)
      }
    },
    [fetchData, dataSourceURL]
  )

  // github request
  const handleGithubRequest = useCallback(
    async (customURL?: string) => {
      const { platformConsoleApiUrl } = getConfig(env)
      if (isGithubUrlValid(customURL || dataSourceURL)) {
        await fetchData({
          method: 'POST',
          url: platformConsoleApiUrl,
          query: QUERIES.githubWidgetData,
          variables: { dataSourceURL: customURL || dataSourceURL },
        })
      } else {
        setErrorMessage(labels.notValidURL)
      }
    },
    [fetchData, dataSourceURL, env]
  )

  // graphql request
  const handleGraphQLQuery = useCallback(
    async (customURL?: string) => {
      if (isValidURL(customURL || dataSourceURL)) {
        if (dataQuery) {
          await fetchData({
            method: 'POST',
            url: customURL || dataSourceURL,
            query: dataQuery,
          })
        } else {
          setErrorMessage(labels.noDataQuery)
        }
      } else {
        setErrorMessage(labels.notValidURL)
      }
    },
    [dataSourceURL, dataQuery, fetchData]
  )

  const handleCXWidgetRequest = useCallback(async () => {
    setErrorMessage('')
    setIsFetching(true)
    const widgetApiHandler = getCXWidgetApiHandler(dataSourceURL)
    // check if valid catalog(widget name) exists in widgetDataApis
    if (!accessToken) throw new Error('Access token denied')
    // fetch data from widgetDataApis
    const data = await widgetApiHandler({ accessToken })
    if (isChartData(data)) {
      setWidgetData({ data, shouldFetch: false })
    } else {
      setErrorMessage(labels.notValidWidgetData)
    }

    setIsFetching(false)
  }, [accessToken, dataSourceURL])

  const resolveFetch = useCallback(
    (customURL?: string) => {
      switch (type) {
        case DataSourceType.GRAPHQL:
          handleGraphQLQuery(customURL)
          break
        case DataSourceType.GITHUB:
          handleGithubRequest(customURL)
          break
        case DataSourceType.API:
          handleRequestToAPI(customURL)
          break
        case DataSourceType.CX:
          handleCXWidgetRequest()
          break
      }
    },
    [type, handleGithubRequest, handleRequestToAPI, handleGraphQLQuery, handleCXWidgetRequest]
  )

  useEffect(() => {
    if (widgetData?.shouldFetch) {
      resolveFetch()
    }
  }, [resolveFetch, widgetData?.shouldFetch])

  useEffect(
    () => () => {
      isCancelled.current = true
    },
    []
  )

  const refetch = async (customURL?: string) => {
    setWidgetData(undefined)
    resolveFetch(customURL)
  }

  return {
    get: refetch,
    isFetching,
    errorMessage,
    data: widgetData?.data,
  }
}
